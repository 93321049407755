import type { PartialAreaEntityDto } from '@peter-park/available-purchase-option'

/**
 * Returns the formatted area name built from the locality and the name
 * @param area the area to format
 * @param separator the separator to use between the locality and the name
 * @returns the formatted area name
 * @example ```ts
   formatAreaName({ locality: 'Paris', name: 'Eiffel Tower' }) // 'Paris - Eiffel Tower'
   formatAreaName({ locality: 'Paris' }) // 'Paris'
   formatAreaName({ name: 'Eiffel Tower' }) // 'Eiffel Tower'
 ```
 */
export function formatAreaName(area: PartialAreaEntityDto, separator = ' - ') {
  return [area.locality, area.name].filter(Boolean).join(separator)
}
