import type { AvailablePurchaseOptionFixedPriceTicketDto } from '@peter-park/available-purchase-option'

/**
 * checks if ticket has area in its list of areas
 * @param ticket the ticket to check
 * @param areaId the area id to search
 * @returns if the ticket has the area or not
 * @remarks if ticket doesn't have areas, it will check the validity area
 */
export function ticketHasArea(ticket: AvailablePurchaseOptionFixedPriceTicketDto, areaId: string) {
  const areaIds = ticket.areas?.map(a => a.areaEntityId) ?? [ticket.validityAreaEntityId]
  return areaIds.includes(areaId)
}
