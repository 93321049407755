<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import type { AvailablePurchaseOptionFixedPriceTicketDto } from '@peter-park/available-purchase-option'
import { logSelectTicket } from '@/utils/logger'
import { userSettingsStore } from '@/stores'
import { useTicketsAreas } from '@/composables/useTicketsAreas'
import { useGetPurchaseOptionsForShop } from '@/composables/openapi/availablePurchaseOption/useGetPurchaseOptionsForShop'
import { ticketHasArea } from '@/utils/ticket'

const props = defineProps<{
  areaEntityId: string | undefined
}>()
const areaEntityId = toRef(props, 'areaEntityId')
const selectedAreaId = ref<string>()

const userSettings = userSettingsStore()
const { latestShopVisited } = storeToRefs(userSettings)
const router = useRouter()
const enabled = computed(() => {
  return !!areaEntityId.value
})
// if the area entity id is not available, we keep the `isEnabled` flag as false
const { purchaseOptionsForShop, suspenseGetPurchaseOptionsForShop } = useGetPurchaseOptionsForShop(areaEntityId, enabled)
await suspenseGetPurchaseOptionsForShop()

const fixedDurationTickets = computed((): AvailablePurchaseOptionFixedPriceTicketDto[] => {
  return purchaseOptionsForShop?.value?.fixedDurationTickets ?? []
})

const filteredTickets = computed(() => {
  const areaId = selectedAreaId.value
  if (!areaId)
    return fixedDurationTickets.value
  return fixedDurationTickets.value.filter(ticket => ticketHasArea(ticket, areaId))
})

const { ticketsAreas } = useTicketsAreas(fixedDurationTickets)
const isMultipleAreas = computed(() => ticketsAreas.value.length > 1)

const { t } = useI18n()

function handleViewTicket(ticketId: string) {
  logSelectTicket(ticketId)
  router.push({ name: 'view-ticket', params: { ticketId, shop: latestShopVisited.value } })
}
</script>

<template>
  <div class="w-full flex flex-col items-center gap-4">
    <UiAreaSelection v-if="isMultipleAreas && selectedAreaId" v-model="selectedAreaId" :areas="ticketsAreas" />
    <div class="bg-dotted min-h-lg w-full p-10">
      <div v-if="isMultipleAreas && !selectedAreaId" class="mx-auto max-w-3xl flex flex-col items-center gap-4 rounded-lg bg-white px-4 py-8 shadow-xl">
        <h3 class="text-xl font-600">
          {{ t('please_select_an_area') }}
        </h3>
        <UiAreaSelection v-model="selectedAreaId" :areas="ticketsAreas" />
        <p class="text-sm text-gray-600">
          {{ t('select_area_to_view_tickets') }}
        </p>
      </div>
      <TicketsGrid v-if="!isMultipleAreas || selectedAreaId" :tickets="filteredTickets" @selected="handleViewTicket" />
    </div>
  </div>
</template>

<style scoped>
.bg-dotted {
  background-image: url("@/assets/dottedCardBackground.svg")
}
</style>
