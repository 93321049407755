import type { MaybeRef } from 'vue'
import type { AvailablePurchaseOptionFixedPriceTicketDto } from '@peter-park/available-purchase-option'
import { formatAreaName } from '@/utils/areas'

export function useTicketsAreas(tickets: MaybeRef<AvailablePurchaseOptionFixedPriceTicketDto[]>) {
  /**
   * internal map to retrieve an area name by its id
   */
  const areaNameMap = computed(() => {
    const result = new Map<string, string>()
    for (const ticket of unref(tickets)) {
      // skip tickets without areas
      if (!ticket.areas)
        continue
      for (const area of ticket.areas) {
        result.set(area.areaEntityId, formatAreaName(area))
      }
    }
    return result
  })

  /**
   * internal map to retrieve the area names of a ticket
   */
  const ticketsAreaNamesMap = computed(() => {
    const result = new Map<string, string[]>()
    for (const ticket of unref(tickets)) {
      const areas = ticket.areas
        ?.map(a => areaNameMap.value.get(a.areaEntityId) ?? '')
        .filter(name => name !== '')
        .sort((a, b) => a.localeCompare(b))
      result.set(ticket.id, areas ?? [])
    }
    return result
  })

  /**
   * A list of all the areas of the tickets
   */
  const ticketsAreas = computed(() => {
    return Array.from(areaNameMap.value.entries())
      .map(([id, name]) => ({ id, name }))
      .sort((a, b) => a.name.localeCompare(b.name))
  })

  /**
   * returns the area names of the ticket
   * @param ticket the ticket to get the area names
   * @returns an array of area names
   */
  function getTicketAreaNames(ticket: AvailablePurchaseOptionFixedPriceTicketDto) {
    return ticketsAreaNamesMap.value.get(ticket.id) ?? []
  }

  return { ticketsAreas, getTicketAreaNames }
}
