<script lang="ts" setup>
import {
  SelectContent,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectPortal,
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'radix-vue'

const props = defineProps<{
  /**
   * list of areas to show in the select
   */
  areas: {
    id: string
    name: string
  }[]
}>()

/**
 * The id of the selected area
 */
const selectedArea = defineModel<string>()

const { t } = useI18n()
</script>

<template>
  <SelectRoot v-model="selectedArea">
    <SelectTrigger class="h-auto max-w-md w-full inline-flex items-center justify-between gap-2 rounded-2 bg-white pa-4 leading-none shadow-sm outline-none ring-2 ring-gray-300 ring-inset focus:ring-2 focus:ring-primary-300">
      <SelectValue :placeholder="t('select_an_area')" class="font-500" />
      <div
        class="i-lucide:chevron-down h-5 w-5"
      />
    </SelectTrigger>

    <SelectPortal>
      <SelectContent
        position="popper"
        :side-offset="5"
        class="SelectContent will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade z-100 mt-2 max-h-50 max-w-md w-md inline-flex rounded bg-white pa-[0.375rem] shadow-sm ring-2 ring-gray-200"
      >
        <SelectViewport>
          <SelectItem
            v-for="area in props.areas" :key="area.id" :value="area.id"
            class="relative h-auto flex select-none items-center justify-between rounded-2 pa-[0.375rem] leading-none data-[disabled]:pointer-events-none data-[highlighted]:bg-gray-50 data-[highlighted]:text-black data-[highlighted]:outline-none"
          >
            <SelectItemText class="pa-[0.625rem]">
              {{ area.name }}
            </SelectItemText>

            <SelectItemIndicator class="inline-flex items-center justify-center">
              <div class="i-lucide:check h-5 w-5 color-primary-600 font-500" />
            </SelectItemIndicator>
          </SelectItem>
        </SelectViewport>
      </SelectContent>
    </SelectPortal>
  </SelectRoot>
</template>
